<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-07-22 10:38:31
 * @LastEditTime: 2022-11-16 14:05:19
 * @LastEditors: xup@imyfone.cn
 * @FilePath: \mfzj\src\views\layout\work\okr\evaluation\components\EvaluteTableHead.vue
-->
<template>
  <div class="evalute-table-head">
    <template v-for="item in nowData" :key="item">
      <div v-if="!item.isCompany" :style="[{ width: item.width + 'px', textAlign: item.align, marginRight: item.mr + 'px' }]">
        <template v-if="item.name === '成果评分'">
          <span class="result-head">
            <span class="request">*</span>{{item.name}} <el-icon class="result-icon" @mouseenter="showDescription = true" @mouseleave="showDescription = false"><WarningFilled /></el-icon>
            <div class="result-descript" v-if="showDescription">
              <p>成果评分标准为：</p>
              <p>0分   无任何进展</p>
              <p>1分   接近期望目标</p>
              <p>2分   达成期望目标，未达成挑战目标</p>
              <p>3分   几乎达成高挑战性目标</p>
              <p>4分   高挑战性目标，通过各种努力后达成</p>
            </div>
          </span>
        </template>
        <template v-else-if="requireName.includes(item.name) && (tableType === 'self' || tableType === 'highEvalution')">
          <span class="request">*</span>{{item.name}}
        </template>
        <template v-else>
          {{item.name}}
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { toRefs, reactive, computed } from '@vue/reactivity'
export default {
  props: {
    theadData: {
      type: Object,
      default: () => {}
    },
    tableType: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const state = reactive({
      tableType: computed(() => props.tableType),
      nowData: computed(() => props.theadData[props.tableType]),
      showDescription: false,
      requireName: ['上级评分']
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.evalute-table-head {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 907px;
  height: 42px;
  background: #F6F9FA;
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  color: #333;
}

.result-icon {
  color: #ccc;
  vertical-align: sub;
  margin-left: 4px;
  font-size: 16px;
}

.request {
  color: #FF0000;
}

.result-head {
  position: relative;
  .result-descript {
    text-align: left !important;
    position: absolute;
    left: 53px;
    top: 27px;
    padding: 8px;
    width: 257px;
    height: 136px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 6px;
    font-weight: 400;
    font-size: 13px;
    color: #999;
    line-height: 20px;
    transition: all .8s;
    z-index: 500;
  }
  .request {}
}
</style>
