<template>
  <div class="evalute-template">
    <div class="evalute-template-top">
      <span>
        <span class="target-type" v-if="evaluteData.ork_type && showTargetType.includes(route.name)">{{evaluteData.ork_type}}</span>
        <template v-if="showDepartment">
          {{evaluteData.type === 2 ?  evaluteData.user.name : evaluteData.dept.name }}—</template>
          {{evaluteData.cycle.split('—')[1]?.length ? evaluteData.cycle : evaluteData.cycle}}
        </span>
      <!-- <PickDate></PickDate> -->
    </div>

    <div class="evalute-template-content">
      <div class="template-title">
        <div class="template-target">
          <img src="@/assets/img/work/work-okr-flag.svg">
          <span class="default-text-color">目标:</span>
          <div class="target-title word-wrap-block">{{ evaluteData.name }}</div>
        </div>
        <div class="item-progress" v-if="evaluteType !== 'overallRatingOne'">
          <el-progress :stroke-width="24" type="circle" :percentage="allSchedule" color="#7972f0"  ><span></span></el-progress>
          <span class="active-text-color all-target-weight">{{allSchedule}}%</span>
        </div>
      </div>
      <div :class="['evalute-table', evaluteType === 'overallRatingOne' ? 'hideLine' : '']">

        <EvaluteTableHead :theadData="tableHeadData" :tableType="evaluteType"></EvaluteTableHead>
        <!-- 如果是完成状态下 表格1 为单行固定表头 数据为单行 -->
        <EvaluteTableBody
          :ref="getEvaluteRef"
          v-for="(item, index) in (evaluteType === 'overallRatingOne' ? tableBodyData['overallRatingOne'] : evaluteData.key_results)" :key="item"
          :id="index"
          :tBodyData="tableBodyData[evaluteType][index]"
          :tableType="evaluteType"
          @calcScore="calcScore"
          @getAllProgress="getAllProgress"
        >
        </EvaluteTableBody>
      </div>
        <!-- 如果是完成状态下 表格2 数据 -->
      <template v-if="evaluteType === 'overallRatingOne'">
        <div class="evalute-detail">评价详情</div>
        <div class="evalute-table">
          <EvaluteTableHead :theadData="tableHeadData" tableType="overallRatingTwo"></EvaluteTableHead>
          <EvaluteTableBody
            :ref="getEvaluteRef"
            v-for="(item, index) in evaluteData.key_results"
            :id="index" :key="item"
            :tBodyData="tableBodyData['overallRatingTwo'][index]"
            tableType="overallRatingTwo"
            @calcScore="calcScore"
          >
          </EvaluteTableBody>
        </div>
      </template>

      <div class="self-score" v-if="evaluteType === 'self'">
        <div>自评分数: {{selfScore}}</div>
        <div>
          <el-input v-model="selfResult" type="textarea" autosize placeholder="请输入成果总结" resize="none" maxlength="500" />
        </div>
      </div>
      <div v-else-if="evaluteType === 'highEvalution' || evaluteType === 'selfChecking'">
        <div class="target-score">
          <div>自评分数：{{evaluteData.self_evaluate}}</div>
          <div class="word-wrap-block">{{evaluteData.self_evaluate_content}}</div>
        </div>
        <div class="self-score" v-if="evaluteType === 'highEvalution'">
          <div>上级总评: {{highScore}}</div>
          <div>
            <el-input v-model="selfResult" type="textarea" autosize placeholder="请输入成果总结" resize="none" maxlength="500" />
          </div>
        </div>
      </div>

      <div class="evalute-template-bottom">
        <div class="score-detail">
          <p class="active-text-color">目标分数：</p>
          <p>成果评分标准为：</p>
          <p>0分   无任何进展</p>
          <p>1分   接近期望目标</p>
          <p>2分   达成期望目标，未达成挑战目标</p>
          <p>3分   几乎达成高挑战性目标</p>
          <p>4分   高挑战性目标，通过各种努力后达成</p>
        </div>
        <div class="score-btn" v-if="route.name !== 'allTarget'">
          <template v-if="evaluteType === 'self'">
            <el-button @click="confirmEvalute(1)">提交评价</el-button>
            <el-button class="save-high-evalute" @click="confirmEvalute(3)">保存草稿</el-button>
            <el-button @click="back" class="cancel-evalute">返回</el-button>
          </template>
          <template v-else-if="evaluteType === 'selfChecking' && !isCompany">
            <template  v-if="evaluteData.evaluate_status === 2">
              <span class="cursor-pointer">上级正在评价...</span>
              <span class="cursor-pointer" hidden></span>
            </template>
            <template  v-else>
              <span class="cursor-pointer">等待上级评价...</span>
              <span class="cursor-pointer" @click="withdrawSelf">撤回自评</span>
            </template>
          </template>
          <template v-else-if="evaluteType === 'highEvalution'">
            <el-button class="submit-high-evalute" @click="confirmEvalute(4)">提交评价</el-button>
            <el-button class="save-high-evalute" @click="confirmEvalute(3)">保存草稿</el-button>
            <el-button class="cancel-evalute" @click="cancelTemp">返回</el-button>
          </template>
        </div>
      </div>
      <slot name="evaluateFooter"></slot>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from '@vue/reactivity'
import { onMounted, watch, nextTick } from 'vue'
import EvaluteTableHead from './EvaluteTableHead.vue'
import EvaluteTableBody from './EvalutableBody.vue'
import { confirm, dialogMsg } from '@/utils/dialogMsg.js'
import { onBeforeRouteLeave, useRouter, useRoute } from 'vue-router'
import { selfEvaluate, changeEvaluateStatus, leadEvaluate, getOkrLeader, selfEvaluateDraft, leadEvaluateDraft } from '@/apis/okr.js'
import { useStore } from 'vuex'
import mySocket from '@/mixin/socket.js'

export default {
  components: {
    EvaluteTableHead,
    EvaluteTableBody
    // PickDate
  },
  emits: ['evaluateSuccess', 'quitEvalute'],
  props: {
    evaluteId: {
      type: Number,
      default: 1
    },
    evaluteData: {
      type: Object,
      default: () => {}
    },
    showDepartment: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      evaluteData: computed(() => props.evaluteData), // 评价相关数据
      evaluteId: computed(() => props.evaluteId), // 评价id
      targetTitle: '', // 目标
      submitOrSaveState: false, // 是否提交
      evaluteType: '', // 评价类型
      tableHeadData: { // self highEvalution overallRatingOne overallRatingTwo 表头信息
        self: [
          { name: '关键结果', width: 278, align: 'left', mr: 10 },
          { name: '进度', width: 82, align: 'center', mr: 10 },
          { name: '成果评分', width: 150, align: 'center', mr: 10 },
          { name: '成果总结', width: 100, align: 'left' }
        ],
        selfChecking: [
          { name: '关键结果', width: 278, align: 'left', mr: 10 },
          { name: '进度', width: 82, align: 'center', mr: 10 },
          { name: '成果评分', width: 150, align: 'center', mr: 10 },
          { name: '成果总结', width: 100, align: 'left' }
        ],
        highEvalution: [
          { name: '关键结果', width: 248, mr: 10, align: 'left' },
          { name: '进度', width: 82, mr: 10, align: 'center' },
          { name: '自评', width: 222, mr: 10, align: 'center' },
          { name: '上级评分', width: 95, mr: 100, align: 'center' },
          { name: '上级总结', mr: 0, align: 'left' }
        ],
        overallRatingOne: [
          { name: '总进度', mr: 203 },
          { name: '自评', mr: process.env.VUE_APP_COMPANY_NAME === props.evaluteData?.dept?.name ? 374 : 314 },
          { name: '上级评价', mr: 174, isCompany: computed(() => process.env.VUE_APP_COMPANY_NAME === props.evaluteData?.dept?.name) },
          { name: '最终结果', mr: 0 }
        ],
        overallRatingTwo: [
          { name: '关键结果', mr: 257 },
          { name: '进度', mr: 131 },
          { name: '自评', mr: process.env.VUE_APP_COMPANY_NAME === props.evaluteData?.dept?.name ? 214 : 175 },
          { name: '上级评价', mr: 86, isCompany: computed(() => process.env.VUE_APP_COMPANY_NAME === props.evaluteData?.dept?.name) },
          { name: '最终结果', mr: 0 }
        ]
      },
      tableBodyData: { // self selfChecking highEvalution overallRatingOne overallRatingTwo 表格中每一行数据
        self: [
          [
            { name: '关键结果', content: '', width: '278px', mr: 10, align: 'left' },
            { name: '进度', progress: 75, mr: 10, width: '82px', align: 'center' },
            { name: '成果评分', select: true, selectText: '评分', mr: 44, width: '120px', align: 'center' },
            { name: '成果总结', input: true, placeholder: '请输入成果总结', flex: 1, mr: 0, align: 'left', width: 0 }
          ]
        ],
        selfChecking: [
          [
            { name: '关键结果', content: '', width: '278px', mr: 10, align: 'left' },
            { name: '进度', progress: 75, mr: 10, width: '82px', align: 'center' },
            { name: '成果评分', content: '', mr: 44, width: '120px', align: 'center' },
            { name: '成果总结', content: '', flex: 1, mr: 0, align: 'left', width: 0 }
          ]
        ],
        highEvalution: [
          [
            { name: '关键结果', content: '', width: '248px', mr: 10, align: 'left' },
            { name: '进度', progress: 75, width: '82px', mr: 10, align: 'center' },
            { name: '自评', content: '', width: '222px', mr: 10, align: 'center' },
            { name: '上级评分', select: true, selectText: '评分', mr: 30, lead_evaluate: 0, width: '85px', align: 'center' },
            { name: '上级总结', input: true, placeholder: '请输入成果总结', flex: 1, mr: 0, align: 'left', lead_evaluate_content: '' }
          ]
        ],
        overallRatingOne: [
          [
            { name: '总进度', progress: 75, mr: 10, align: 'left' },
            { name: '自评', content: '', mr: 10, width: '395px', align: 'center' },
            { name: '上级评价', content: '', width: '300px', mr: 60, align: 'center', isCompany: computed(() => process.env.VUE_APP_COMPANY_NAME === props.evaluteData?.dept?.name) },
            { name: '最终结果', content: '', mr: 0, align: 'center', flex: 1 }
          ]
        ],
        overallRatingTwo: [
          [
            { name: '关键结果', content: '', mr: 10, align: 'left', width: '280px' },
            { name: '进度', progress: 75, mr: 10, width: '70px', align: 'center' },
            { name: '自评', content: '', mr: 10, width: '230px', align: 'center' },
            { name: '上级评价', content: '', mr: 10, width: '195px', align: 'center', isCompany: computed(() => process.env.VUE_APP_COMPANY_NAME === props.evaluteData?.dept?.name) },
            { name: '最终结果', content: '', mr: 0, flex: 1, align: 'center' }
          ]
        ]
      },
      selfResult: '',
      evaluteRefs: [],
      selfScore: 0, // 自评分数分数
      highScore: 0, // 上级评价分数
      arrData: [], // switch 中的临时变量
      initData: {}, // 进入页面时加载完数据后的初始化数据 用来和 离开页面时所保存的数据相比较
      isSave: false,
      okr: computed(() => store.state.okr),
      showComent: false,
      user: computed(() => store.state.userInfo.userInfos),
      commenEditorRef: null,
      isComment: false,
      isCompany: computed(() => process.env.VUE_APP_COMPANY_NAME === props.evaluteData?.dept?.name),
      showTargetType: ['allTarget', 'targetAligned', 'evaluation'],
      allSchedule: 0
    })

    // 上级评价获取初始化数据
    const getInitData = () => {
      const data = getData()
      return data
    }

    // 检测是否需要保存草稿
    const examSave = () => {
      const localData = getData()
      JSON.stringify(localData) === JSON.stringify(state.initData) ? state.isSave = false : state.isSave = true
    }
    watch(() => state.evaluteData, (newValue) => {
      switch (newValue.evaluate_status) {
        case 0 : // 自评当前显示
        case 5 : // 自评保存草稿
          state.arrData = []
          state.evaluteType = 'self'
          nextTick(() => {
            newValue.key_results.forEach((i, index) => {
              const templateData = JSON.parse(JSON.stringify(state.tableBodyData.self[0]))
              templateData[0].content = `${i.tag}: ${i.name} 权重:sp ${i.weights}%`
              templateData[1].progress = i.schedule
              // 自评数据回显
              state.evaluteRefs[index].value = i.self_evaluate + '分'
              state.evaluteRefs[index].inputValue = i.self_evaluate_content
              state.arrData.push(templateData)
            })
            // 获取总自评结果/分数/总进度
            state.selfResult = newValue.self_evaluate_content
            state.selfScore = parseFloat(newValue.self_evaluate)
            state.allSchedule = newValue.schedule
            state.tableBodyData.self = state.arrData
          })

          break
        case 1: // selfChecking highEvalution // 自评提交
        case 2: // 2-上级正在评价
        case 3: // 3-上级评价保存草稿
          // 上级评价展示
          if (newValue.isHighEvalute) {
            state.arrData = []
            state.evaluteType = 'highEvalution'
            nextTick(() => {
              newValue.key_results.forEach((i, index) => {
                const templateData = JSON.parse(JSON.stringify(state.tableBodyData.highEvalution[0]))
                templateData[0].content = `${i.tag}: ${i.name} 权重:sp ${i.weights}%`
                templateData[1].progress = i.schedule
                templateData[2].content = i.self_evaluate + '分' + i.self_evaluate_content
                if (newValue.evaluate_status === 3 || newValue.evaluate_status === 2) {
                  i.lead_evaluate && (templateData[3].lead_evaluate = i.lead_evaluate)
                  i.lead_evaluate_content && (templateData[4].lead_evaluate_content = i.lead_evaluate_content)
                  // 进行上级评价草稿数据回显
                  state.evaluteRefs[index].value = i.lead_evaluate + '分'
                  state.evaluteRefs[index].inputValue = i.lead_evaluate_content
                }
                state.arrData.push(templateData)
              })
              // 获取上级总评结果/分数
              newValue.lead_evaluate_content && (state.selfResult = newValue.lead_evaluate_content)
              newValue.lead_evaluate && (state.highScore = parseFloat(newValue.lead_evaluate))
              state.tableBodyData.highEvalution = state.arrData
            })
          } else {
            // 上级评价中 职员展示
            state.arrData = []
            state.evaluteType = 'selfChecking'
            newValue.key_results.forEach(i => {
              const templateData = JSON.parse(JSON.stringify(state.tableBodyData.selfChecking[0]))
              templateData[0].content = `${i.tag}: ${i.name} 权重:sp ${i.weights}%`
              templateData[1].progress = i.schedule
              templateData[2].content = i.self_evaluate + '分'
              templateData[3].content = i.self_evaluate_content
              state.arrData.push(templateData)
            })
            state.tableBodyData.selfChecking = state.arrData
          }
          // 获取总进度
          state.allSchedule = newValue.schedule
          break
        case 4: // 上级评价提交
          state.arrData = []
          state.evaluteType = 'overallRatingOne'
          // 完成状态下 下方表格数据
          newValue.key_results.forEach(i => {
            const templateData = JSON.parse(JSON.stringify(state.tableBodyData.overallRatingTwo[0]))
            // console.log(templateData)
            templateData[0].content = `${i.tag}: ${i.name} 权重:sp ${i.weights}%`
            templateData[1].progress = i.schedule
            templateData[2].content = i.self_evaluate + '分' + i.self_evaluate_content
            templateData[3].content = i.lead_evaluate + '分' + i.lead_evaluate_content
            templateData[4].content = i.fraction + '分'
            state.arrData.push(templateData)
          })
          state.tableBodyData.overallRatingTwo = state.arrData
          // 完成状态下 上方表格数据
          state.tableBodyData.overallRatingOne[0][0].progress = newValue.schedule
          state.tableBodyData.overallRatingOne[0][1].content = newValue.self_evaluate + '分' + newValue.self_evaluate_content
          state.tableBodyData.overallRatingOne[0][2].content = newValue.lead_evaluate + '分' + newValue.lead_evaluate_content
          state.tableBodyData.overallRatingOne[0][3].content = newValue.fraction + '分'

          // console.log(state.tableBodyData.overallRatingOne, state.tableBodyData.overallRatingTwo)
          break
      }
    }, { immediate: true, deep: true })

    // 离开当前页面
    onBeforeRouteLeave((to, from, next) => {
      examSave()
      if (!state.submitOrSaveState && (state.evaluteType === 'highEvalution' || state.evaluteType === 'self') && state.isSave) {
        confirmEvalute(3, next)
      } else {
        // 没有提交 上级评价 没有修改内容 并且是在评价中的状态  需要修改为评价状态
        next()
      }
    })

    onMounted(() => {
      nextTick(() => {
        if (state.evaluteType === 'highEvalution' || state.evaluteType === 'self') {
          state.initData = getInitData()
          console.log(state.initData)
        }
      })
    })

    const getEvaluteRef = (el) => {
      if (el) {
        !state.evaluteRefs.find(i => i.id === el.id) && state.evaluteRefs.push(el)
      }
    }

    // 跳转页面
    const submit = () => {
      state.submitOrSaveState = true
      store.commit('okr/setHighComenting', false) // 修改评价中状态
      router.push({ name: 'evaluation', params: { tabName: '待评价', evaluteSuccess: true } })
    }
    const saveDraft = () => {
      state.submitOrSaveState = true
      store.commit('okr/setHighComenting', false) // 修改评价中状态
      router.push({ name: 'evaluation', params: { tabName: '待评价' } })
    }

    // 取消按钮返回上一级页面
    const cancelTemp = () => {
      store.commit('okr/setHighComenting', false) // 修改评价中状态
      changeEvaluateStatus({ id: state.evaluteData.id, evaluate_status: store.state.okr.evaluateStatusAgo })
      router.push({ name: 'evaluation', params: { tabName: '待评价' } })
    }

    // 获取提交接口需要的数据
    const getData = (status) => {
      const isSelf = state.evaluteType === 'self'
      const data = {
        id: state.evaluteData.id,
        fraction: isSelf ? state.selfScore : state.highScore,
        content: state.selfResult || '',
        schedule: isSelf ? {} : undefined,
        self_evaluate: isSelf ? {} : undefined,
        self_evaluate_content: isSelf ? {} : undefined,
        lead_evaluate: !isSelf ? {} : undefined,
        lead_evaluate_content: !isSelf ? {} : undefined,
        evaluate_status: !isSelf ? status : undefined
      }
      state.evaluteRefs.forEach((i, index) => {
        isSelf ? data.self_evaluate[state.evaluteData.key_results[index].kr_id] = parseFloat(i.value) : data.lead_evaluate[state.evaluteData.key_results[index].kr_id] = parseFloat(i.value)
        isSelf ? data.self_evaluate_content[state.evaluteData.key_results[index].kr_id] = (i.inputValue) : data.lead_evaluate_content[state.evaluteData.key_results[index].kr_id] = (i.inputValue)
        isSelf && (data.schedule[state.evaluteData.key_results[index].kr_id] = (state.tableBodyData[state.evaluteType][index].filter(item => 'progress' in item && item.name === '进度')[0].progress))
      })
      return data
    }
    const { sendToSocket } = mySocket()
    // 提交评价
    const confirmEvalute = (status = 1, beforeRouter) => {
      let str = ''
      switch (status) {
        case 3:
          str = '是否保存草稿'
          if (beforeRouter) str = '页面发生变更, 是否不保存草稿退出'
          break
        case 4:
          str = '确定提交评价吗?'
          break
        default:
          str = '确定提交自评吗？'
      }
      confirm({
        message: str,
        closeOnClickModal: false,
        success: async () => {
          if (beforeRouter && status === 3) {
            // 确认离开页面
            changeEvaluateStatus({ id: state.evaluteData.id, evaluate_status: 1 })
            return beforeRouter()
          }
          if (status !== 3) {
            if (state.evaluteRefs.filter(i => !i.value.trim()).length > 0) return dialogMsg('error', '请填写完评分后提交')
            // if (state.evaluteRefs.filter(i => !i.inputValue.trim()).length > 0) return dialogMsg('error', '请填写kr成果总结')
            if (!state.selfResult.trim().length) return dialogMsg('error', '请填写成果总结')
          }
          const data = getData(status)
          if (status === 3 && !beforeRouter) {
            // 保存草稿
            const { code, msg } = state.evaluteType === 'self' ? await selfEvaluateDraft(data) : await leadEvaluateDraft(data)
            if (code === 0) {
              dialogMsg('success', '保存成功')
              emit('evaluateSuccess')
              // 刷新待评价页面
              if (state.evaluteType === 'highEvalution') saveDraft()
            } else {
              dialogMsg('error', msg)
            }
            return
          }

          // 自评
          if (state.evaluteType === 'self') {
            selfEvaluate(data).then(({ code, msg }) => {
              if (code === 0) {
                dialogMsg('success', '提交成功')
                emit('evaluateSuccess')
                getOkrLeader({ dept_id: state.evaluteData.dept_id }).then((res) => {
                  if (res.code === 0) {
                    res.data[0] && res.data[0].id && sendToSocket(res.data[0].id, 8)
                  }
                })
              } else {
                // 部门/成员不存在，只刷新页面
                code === 80009 && emit('evaluateSuccess')
                dialogMsg('error', msg)
              }
            })
          } else { // 上级提交评价 4 评价
            leadEvaluate(data).then(({ code, data, msg }) => {
              if (code === 0) {
                dialogMsg('success', msg)
                submit()
              } else {
                dialogMsg('error', msg)
                // 部门/成员不存在，返回刷新页面
                code === 80009 && submit()
              }
            })
          }
        },
        cancel: () => {
          // 取消离开页面
          if (beforeRouter && status === 3) {
            changeEvaluateStatus({ id: state.evaluteData.id, evaluate_status: 2 })
          }
          state.submitOrSaveState = false
        }
      })
    }

    // 撤回自评
    const withdrawSelf = () => {
      changeEvaluateStatus({ id: state.evaluteData.id, evaluate_status: 0 }).then(({ code, msg }) => {
        if (code === 0) {
          dialogMsg('success', msg)
          emit('evaluateSuccess')
        } else {
          dialogMsg('error', msg)
        }
      })
    }

    // 计算分数
    const calcScore = () => {
      // console.log(state.evaluteData.key_results) // 权重i.weights
      // console.log(state.evaluteRefs) // 分数 i.value
      let score = 0
      state.evaluteRefs.forEach((i, index) => {
        i.value && (score += parseFloat(i.value) * (state.evaluteData.key_results[index].weights / 100))
      })
      score = parseFloat(score.toFixed(10))
      state.evaluteType === 'self' ? state.selfScore = score : state.highScore = score
    }

    const back = () => {
      examSave()
      if (state.isSave) {
        confirmEvalute(3, quitEvalute)
      } else {
        emit('quitEvalute')
      }
    }

    const quitEvalute = () => {
      emit('quitEvalute')
    }
    const getAllProgress = () => {
      let allSchedule = 0
      state.tableBodyData.self.forEach(item => {
        allSchedule += Math.floor((parseFloat(item[0].content.split('权重:sp')[1]) * item[1].progress / 100))
      })
      state.allSchedule = allSchedule
    }

    return {
      ...toRefs(state),
      confirmEvalute,
      getEvaluteRef,
      router,
      cancelTemp,
      withdrawSelf,
      calcScore,
      back,
      route,
      getAllProgress
    }
  }
}
</script>

<style lang="less" scoped>
.evalute-template {
  background: #FFF;
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  width: 955px;
  margin-top: 8px;
  .evalute-template-top {
    display: flex;
    justify-content: space-between;
    height: 46px;
    padding: 14px 24px;
    border-bottom: 1px solid #F1F1F1;
    span {
      font-weight: 700;
      font-size: 14px;
      color: @active-text-color;
    }
    .target-type {
      background-color: @active-text-color;
      border-radius: 10px;
      padding: 0 4px;
      margin-right: 5px;
      color: @colorWhite;
      font-weight: 500;
    }
  }

  .evalute-template-content {
    padding-top: 16px;
    .template-title {
      display: flex;
      justify-content: space-between;
    }
    .template-target {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      color: #333;
      span {
        display: inline-block;
        width: 48px;
        margin-left: 12px;
        margin-right: 4px;
      }
      .target-title {
        flex: 1;
      }
    }

    .item-progress {
      display: flex;
      align-items: center;
    }
    :deep(.el-progress) {
      vertical-align: middle;
      margin-right: 9px;
      .el-progress-circle {
        width: 20px !important;
        height: 20px !important;
      }
    }

    .evalute-table {
      margin-top: 16px;
    }
  }

  .self-score,
  .target-score {
    width: 907px;
    border-bottom: 1px solid #F1F1F1;
    padding: 15px;
    margin: 0 24px;
    div:first-child {
      font-size: 14px;
      color: #333;
      // margin-bottom: 8px;
    }
    .el-textarea {
      position: relative;
      margin-top: 6px;
      &::before {
        content: '*';
        position: absolute;
        left: -8px;
        top: 2px;
        color: #FF0000;
      }
      :deep(.el-textarea__inner) {
        padding: 0 !important;
        border: none;
        color: #333 !important;
        &::placeholder {
          font-size: 13px;
          color: #999;
        }
      }
    }
    .word-wrap-block{
      white-space: pre-wrap;
    }
  }
  .target-score {
    div:last-child {
      font-size: 13px;
      color: #333;
      margin-top: 8px;
    }
  }
  .evalute-detail {
    color: #333;
    font-weight: 700;
    font-size: 16px;
    padding-top: 25px;
    line-height: 20px;
    border-top: 1px solid #f1f1f1;
  }
  .evalute-template-bottom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    p {
      font-size: 13px;
      line-height: 20px;
      color: #999;
    }
    .score-detail {
      padding: 12px 0 0 18px;
      p:first-child {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 8px;
      }
    }
      .score-btn {
        :deep(.el-button) {
          width: 88px;
          height: 30px;
          line-height: 30px;
          min-height: 0;
          border-radius: 30px;
          font-size: 13px;
          font-weight: 400;
          padding: 0;
          border: 0;
          margin-top: 13px;
          &:first-child {
            background: @active-text-color;
            color: #FFFFFF;
          }
          &:last-child {
            background: #FFFFFF;
            border: 1px solid @active-text-color;
            color:@active-text-color;
            border-radius: 30px;
            margin-left: 12px;
          }
        }
        span {
          display: inline-flex;
          margin-top: 16px;
        }
        span:first-child {
          width: 97px;
          height: 24px;
          padding: 4px 8px;
          background: @active-text-color;
          border-radius: 6px;
          color: #fff;
          font-size: 12px;
        }
        span:last-child {
          color: @active-text-color;
          margin-left: 24px;
          font-size: 13px;
        }
      }
  }
}
.template-title,
.evalute-table,
.evalute-detail,
.evalute-template-bottom {
  padding: 0 24px;
}

.hideLine .overallRatingOne {
  border-bottom: 0
}
.save-high-evalute {
  background: #FFFFFF !important;
  border: 1px solid @active-text-color !important;
  color: @active-text-color;
  border-radius: 30px;
}
.cancel-evalute {
  background: #F6F9FA !important;
  border: 1px solid #F1F1F1 !important;
  color: #333 !important;
  border-radius: 44px;
}
</style>
