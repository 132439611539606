<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-07-22 11:07:19
 * @LastEditTime: 2024-05-14 10:57:47
 * @LastEditors: liujx@imyfone.cn
 * @FilePath: \mfzj\src\views\layout\work\okr\evaluation\components\EvalutableBody.vue
-->
<template>
  <div class="evalute-table-body" :class="tableType">
  <template v-for="(item, index) in nowData" :key="item">
      <div v-if="!item.isCompany" :style="[{ marginRight: item.mr + 'px', width: item?.width, textAlign: item.align}, item.name === '成果总结' || '自评' || '上级评价' ? 'word-wrap: break-word;white-space: pre-wrap;' : '', item.flex ? 'flex: 1;' : '']">
        <template v-if="item.content">
          <template v-if="item.content.length > 8 && item?.content?.slice(-10).includes('权重:sp') && index === 0">
            {{item.content.split('权重:sp')[0]}}<span class="active-text-color"> 权重: {{item.content.split('权重:sp')[1]}}</span>
          </template>
          <template v-else-if="item.name === '自评' || item.name === '上级评价'">
              <div>{{item.content.split('分')[0] + '分'}}</div>
              <div>{{item.content.slice(item.content.split('分')[0].length + 1, item.content.length)}}</div>
          </template>
          <template v-else-if="item.name === '最终结果'">
            <span class="active-text-color font-bold">{{item.content}}</span>
          </template>
          <template v-else>
            <span>{{ item.content }}</span>
          </template>
        </template>
        <template v-if="item.content === 0 && item.name === '最终结果'">
          0
        </template>
        <template v-else-if="item.name === '进度'">
          <el-progress :stroke-width="24" type="circle" :percentage="item.progress" color="#7972f0"  ><span></span></el-progress>
          <template v-if="tableType === 'self'">
            <el-input-number v-model="item.progress" @change="getAllProgress" :precision="0" :min="0" value-on-clear="min" :max="100" :controls="false"/>
            <span>%</span>
          </template>
          <template v-else>
            <span class="active-text-color">{{item.progress}}%</span>
          </template>
        </template>
        <template v-else-if="item.name === '总进度'">
          <el-progress :stroke-width="24" type="circle" :percentage="item.progress" color="#7972f0"  ><span></span></el-progress>
          <span class="active-text-color">{{item.progress}}%</span>
        </template>
        <template v-else-if="item.select">
          <el-select :popper-append-to-body="false" v-model="value" class="m-2 evalute-select" :placeholder="item.selectText" @change="selectScore">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </template>
        <template v-else-if="item.input">
          <el-input v-model="inputValue" type="textarea" :placeholder="item.placeholder" resize="none" autosize maxlength="500" />
        </template>
      </div>
  </template>
  </div>
</template>

<script>
import { toRefs, reactive, computed } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'
export default {
  props: {
    tBodyData: {
      type: Object,
      default: () => {}
    },
    tableType: {
      type: String,
      default: ''
    },
    id: {
      type: Number
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      nowData: computed(() => props.tBodyData),
      tableType: computed(() => props.tableType),
      id: computed(() => props.id),
      value: '',
      options: [
        {
          value: '0分',
          label: '0分'
        },
        {
          value: '1分',
          label: '1分'
        },
        {
          value: '2分',
          label: '2分'
        },
        {
          value: '3分',
          label: '3分'
        },
        {
          value: '4分',
          label: '4分'
        }],
      inputValue: ''
    })

    const selectScore = () => {
      emit('calcScore')
    }

    watch(() => state.nowData, (newValue) => {
      // console.log(newValue)
      if (newValue) {
        newValue.length > 3 && newValue[3].lead_evaluate !== undefined && (state.value = state.options[newValue[3].lead_evaluate].value)
        newValue.length > 4 && newValue[4].lead_evaluate_content !== undefined && (state.inputValue = newValue[4].lead_evaluate_content)

        newValue[3].lead_evaluate === 0 && (state.value = '')
      }
    }, { immediate: true, deep: true })

    // 更新自评总进度
    const getAllProgress = () => {
      emit('getAllProgress')
    }

    return {
      ...toRefs(state),
      selectScore,
      getAllProgress
    }
  }
}
</script>

<style lang="less" scoped>
.evalute-table-body {
  padding: 16px;
  display: flex;
  align-items: center;
  width: 907px;
  background: #FFFFFF;
  font-size: 13px;
  border-bottom: 1px solid #F1F1F1;
  color: #333;
}
.evalute-select {
  position: relative;
  left: 15px;
  :deep(.el-input__inner) {
    padding: 7px 14px;
    width: 84px;
    height: 30px;
    background: #F6F9FA;
    border: 1px solid #F1F1F1;
    border-radius: 44px;
  }
  :deep(.el-input__suffix) {
    position: absolute;
    right: 6px;
    top: -6px;
    .el-input__suffix-inner i {
      height: 30px;
      line-height: 15px;
      font-weight: 700;
      color: #333;
    }
  }
}
.el-textarea {
  :deep(.el-textarea__inner) {
    padding: 0 !important;
    border: none;
    color: #333 !important;
    &::placeholder {
      font-size: 13px;
      color: #999;
    }
  }
}
.el-progress {
  margin-right: 6px;
}
:deep(.el-select__popper) {
  transform: translateY(-6px);
  border: 1px solid #F1F1F1;
  border-radius: 6px;
}
:deep(.el-popper__arrow) {
  display: none !important;
}
:deep(.el-select-dropdown__list .el-select-dropdown__item) {
  height: 26px;
  line-height: 26px;
  padding: 0 12px 0 51px !important;
  color: #333;
  font-size: 13px;
  &:hover {
    background: #f1f4f4;
  }
  &.selected {
    position: relative;
    color: #333;
    font-weight: 400;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 15px;
      background: url(../../../../../../assets/img/work/work-score-select-new.svg) no-repeat;
      width: 15px;
      height: 100%;
      top: 9px;
    }
  }
}
:deep(.el-input-number){
          font-size: 13px;
          width: 36px;
          height: 24px;
          margin-left: 3px;
          margin-right: 2px;
          color: @default-text-color;
          .el-input {
            line-height: 0;
            width: 36px;
            height: 24px;
            line-height: 24px;
          }
          .el-input__inner {
            background: #F6F9FA;
            border: 1px solid #F1F1F1;
            border-radius: 4px;
            padding: 0;
            height: 24px;
            line-height: 24px;
            color: #333;
            font-size: 13px;
          }
          span {
            font-size: 14px;
          }
        }
</style>
